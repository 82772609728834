body {
    margin: 0;
    font-family: 'Inter', serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
    color: inherit;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

a.active div {
    color: #0b71e0;
    font-weight: bolder;
    /* text-decoration: underline; */
}

@keyframes fly {
    0% {
        transform: translateX(0) translateY(0);
    }
    100% {
        transform: translateX(120vw) translateY(-100vh);
    }
}
.Flying-Astronaut-Mascot-space {
    width: 100vw;
    height: 100vh;
    position: fixed;
}
.Flying-Astronaut-Mascot-spaceship {
    position: absolute;
    bottom: 0;
    left: -512px; /* Start from off-screen on the left */
    font-size: 50px;
    animation: fly 3s linear;
}
